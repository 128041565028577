// src/starter-kit.ts
import { Extension } from "@tiptap/core";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Heading } from "@tiptap/extension-heading";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Strike } from "@tiptap/extension-strike";
import { Text } from "@tiptap/extension-text";
var StarterKit = Extension.create({
  name: "starterKit",
  addExtensions() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
    const extensions = [];
    if (this.options.blockquote !== false) {
      extensions.push(Blockquote.configure((_a = this.options) == null ? void 0 : _a.blockquote));
    }
    if (this.options.bold !== false) {
      extensions.push(Bold.configure((_b = this.options) == null ? void 0 : _b.bold));
    }
    if (this.options.bulletList !== false) {
      extensions.push(BulletList.configure((_c = this.options) == null ? void 0 : _c.bulletList));
    }
    if (this.options.code !== false) {
      extensions.push(Code.configure((_d = this.options) == null ? void 0 : _d.code));
    }
    if (this.options.codeBlock !== false) {
      extensions.push(CodeBlock.configure((_e = this.options) == null ? void 0 : _e.codeBlock));
    }
    if (this.options.document !== false) {
      extensions.push(Document.configure((_f = this.options) == null ? void 0 : _f.document));
    }
    if (this.options.dropcursor !== false) {
      extensions.push(Dropcursor.configure((_g = this.options) == null ? void 0 : _g.dropcursor));
    }
    if (this.options.gapcursor !== false) {
      extensions.push(Gapcursor.configure((_h = this.options) == null ? void 0 : _h.gapcursor));
    }
    if (this.options.hardBreak !== false) {
      extensions.push(HardBreak.configure((_i = this.options) == null ? void 0 : _i.hardBreak));
    }
    if (this.options.heading !== false) {
      extensions.push(Heading.configure((_j = this.options) == null ? void 0 : _j.heading));
    }
    if (this.options.history !== false) {
      extensions.push(History.configure((_k = this.options) == null ? void 0 : _k.history));
    }
    if (this.options.horizontalRule !== false) {
      extensions.push(HorizontalRule.configure((_l = this.options) == null ? void 0 : _l.horizontalRule));
    }
    if (this.options.italic !== false) {
      extensions.push(Italic.configure((_m = this.options) == null ? void 0 : _m.italic));
    }
    if (this.options.listItem !== false) {
      extensions.push(ListItem.configure((_n = this.options) == null ? void 0 : _n.listItem));
    }
    if (this.options.orderedList !== false) {
      extensions.push(OrderedList.configure((_o = this.options) == null ? void 0 : _o.orderedList));
    }
    if (this.options.paragraph !== false) {
      extensions.push(Paragraph.configure((_p = this.options) == null ? void 0 : _p.paragraph));
    }
    if (this.options.strike !== false) {
      extensions.push(Strike.configure((_q = this.options) == null ? void 0 : _q.strike));
    }
    if (this.options.text !== false) {
      extensions.push(Text.configure((_r = this.options) == null ? void 0 : _r.text));
    }
    return extensions;
  }
});

// src/index.ts
var src_default = StarterKit;
export {
  StarterKit,
  src_default as default
};
